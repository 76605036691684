import React from 'react';

const Badminton: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 56.803 56.803"
      {...props}
    >
      <g>
        <g>
          <path
            d="M47.636,45.308c-1.345-3.24-2.575-6.5-3.589-9.861c-0.031-0.201-0.069-0.402-0.123-0.602
			c-0.977-3.627-1.953-7.255-2.93-10.883c2.582-1.148,4.918-2.982,6.844-4.986c0.71-0.741,0.908-1.905,0.361-2.804
			c-1.373-2.247-2.746-4.495-4.121-6.742c-1.553-2.541-5.556-0.218-3.996,2.335c1.06,1.732,2.117,3.464,3.177,5.197
			c-1.691,1.515-3.649,2.802-5.828,3.364c-0.117,0.03-0.218,0.076-0.321,0.118c-0.56-0.031-1.115,0.042-1.615,0.229
			c-0.317,0.12-0.603,0.271-0.858,0.444c-0.021-0.001-0.038-0.007-0.06-0.007c-3.918-0.081-7.412-1.333-10.688-3.443
			c-0.787-0.507-1.555-0.463-2.161-0.115c-2.367-3.012-3.075-4.324-3.282-4.797c0.986-1.852-0.002-5.197-1.679-7.313
			c-2.052-2.592-5.258-3.486-7.146-1.99c-1.887,1.495-1.752,4.82,0.301,7.412c1.67,2.11,4.686,3.833,6.713,3.314
			c0.438,0.351,1.695,1.483,4.095,4.489c-0.458,0.979-0.339,2.246,0.823,2.995c3.513,2.262,7.365,3.642,11.484,3.988
			c0.021,0.094,0.041,0.189,0.065,0.283c1.058,3.926,2.116,7.854,3.172,11.779c0,0.002,0.001,0.004,0.001,0.004
			c-2.841,2.233-5.54,4.682-8.613,6.582c-2.532,1.565-0.21,5.569,2.335,3.994c3.515-2.175,6.528-5.052,9.831-7.519
			c0.181-0.133,0.328-0.281,0.457-0.438c0.166-0.004,0.33-0.017,0.491-0.039c0.658,1.873,1.37,3.727,2.125,5.569
			c-2.085,2.322-3.979,4.802-5.575,7.49c-1.526,2.571,2.475,4.899,3.994,2.336c1.674-2.819,3.676-5.345,5.924-7.726
			c0.682-0.721,0.767-1.561,0.512-2.287C47.727,45.56,47.69,45.437,47.636,45.308z M10.557,10.36
			C8.783,8.119,8.59,5.307,10.126,4.09c1.537-1.218,4.23-0.384,6.005,1.857c1.751,2.212,2.437,5.45,1.426,6.701l-0.803,0.636
			C15.305,13.981,12.309,12.571,10.557,10.36z M18.35,14.515c-0.209,0.165-0.59,0.031-0.851-0.298
			c-0.262-0.33-0.304-0.731-0.095-0.896c0.209-0.166,0.588-0.032,0.851,0.298C18.516,13.948,18.559,14.349,18.35,14.515z"
          />
          <circle cx="34.565" cy="14.801" r="4.204" />
          <path
            d="M23.183,6.405c0.071-0.052,0.135-0.11,0.189-0.171l5.273-2.481L27.093,3.1l0.138-1.281
			l-1.488-0.568L25.903,0l-3.966,4.27c-0.075,0.032-0.15,0.075-0.222,0.127c-0.553,0.405-0.674,1.183-0.269,1.738
			C21.851,6.688,22.629,6.81,23.183,6.405z"
          />
        </g>
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  );
};

export default Badminton;

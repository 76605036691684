import React from 'react';

const Travel: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.001 512.001"
      {...props}
    >
      <g>
        <g>
          <path
            d="M496.236,176.587C454.759,44.097,313.262-29.944,180.746,11.516C48.247,52.993-25.803,194.524,15.666,327.014l16.282-5.1
			C-6.715,198.405,62.32,66.46,185.846,27.789c59.84-18.721,123.39-13.024,178.923,16.035
			c55.558,29.076,96.464,78.032,115.194,137.864c18.721,59.831,13.032,123.373-16.035,178.923
			c-29.059,55.558-78.015,96.464-137.855,115.194c-100.157,31.344-209.943-8.239-267.045-96.276l-14.312,9.28
			c47.2,72.787,127.987,114.699,211.734,114.69c24.905,0,50.091-3.702,74.715-11.429c64.181-20.086,116.695-63.968,147.877-123.552
			C510.224,308.933,516.322,240.769,496.236,176.587z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M206.52,119.408l4.896-16.342l-85.291-25.587c-2.985-0.904-6.252-0.085-8.478,2.141l-17.058,17.058
			c-1.987,1.987-2.866,4.827-2.354,7.591c0.512,2.763,2.354,5.092,4.921,6.235l76.762,34.116l6.926-15.591l-65.409-29.076
			l4.674-4.674L206.52,119.408z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M285.747,68.601c0-4.708-3.813-8.529-8.529-8.529c-0.009,0-0.009,0-0.017,0c-18.764,0-36.411,7.309-49.682,20.581
			l-72.326,72.318l-29.434-7.361c-2.908-0.725-5.979,0.119-8.103,2.243l-17.058,17.058c-1.859,1.859-2.763,4.469-2.439,7.071
			c0.324,2.601,1.825,4.921,4.077,6.269l40.82,24.495l24.495,40.82c1.348,2.252,3.659,3.753,6.269,4.077
			c0.341,0.043,0.691,0.06,1.041,0.06c2.243,0,4.418-0.887,6.039-2.49l17.058-17.058c2.115-2.124,2.968-5.194,2.243-8.103
			l-7.352-29.4l73.478-72.753C278.864,104.772,285.764,87.527,285.747,68.601z M177.385,181.935
			c-2.141,2.115-3.002,5.203-2.277,8.128l7.361,29.434l-5.919,5.919l-19.967-33.28c-0.725-1.203-1.723-2.209-2.925-2.925
			l-33.28-19.967l5.919-5.919l29.434,7.361c2.908,0.725,5.979-0.119,8.103-2.243l75.738-75.738
			c7.847-7.855,17.723-12.947,28.436-14.798c-1.689,10.542-6.491,20.316-13.86,28.035L177.385,181.935z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M268.322,220.802l-25.587-86.399l-16.359,4.845l24.043,81.18l-5.177,4.572l-28.436-65.964l-15.668,6.755l33.093,76.762
			c1.083,2.508,3.301,4.35,5.97,4.947c0.623,0.136,1.245,0.205,1.868,0.205c2.056,0,4.068-0.742,5.638-2.132l18.082-15.949
			C268.279,227.429,269.26,223.992,268.322,220.802z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M166.604,470.286C105.86,499.071,58.208,502.79,35.896,480.487c-38.671-38.671,3.326-151.024,97.683-261.356
			l-12.964-11.088C17.124,329.053-22.681,446.046,23.828,492.547c12.99,12.981,31.336,19.455,54.091,19.455
			c26.525,0,59.038-8.793,95.986-26.304L166.604,470.286z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M455.322,294.851c-1.484-2.772-4.375-4.503-7.523-4.503h-28.845l-23.233-46.458c-1.45-2.891-4.401-4.717-7.634-4.717
			h-85.291c-4.717,0-8.529,3.821-8.529,8.529v68.233c0,4.717,3.813,8.529,8.529,8.529h25.587v93.82c0,4.717,3.812,8.529,8.529,8.529
			h42.645c2.26,0,4.427-0.896,6.039-2.508l34.116-34.116c1.603-1.595,2.499-3.761,2.499-6.03v-31.532l32.683-49.025
			C456.635,300.992,456.806,297.623,455.322,294.851z M406.578,345.318c-0.93,1.399-1.433,3.053-1.433,4.734v30.585l-29.118,29.118
			h-30.585h-0.009v-93.82c0-4.717-3.812-8.529-8.529-8.529h-25.587v-51.174h71.491l23.233,46.458
			c1.45,2.891,4.401,4.717,7.633,4.717h18.184L406.578,345.318z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M461.267,222.115l-31.66-55.405c-1.518-2.661-4.35-4.299-7.403-4.299h-51.174c-2.849,0-5.51,1.424-7.096,3.795
			l-14.525,21.792h-21.024v-42.645h42.645c2.269,0,4.427-0.896,6.03-2.499l31.617-31.617h39.114V94.179h-42.645
			c-2.269,0-4.427,0.896-6.03,2.499l-31.617,31.617h-47.643c-4.717,0-8.529,3.821-8.529,8.529v59.703
			c0,4.708,3.813,8.529,8.529,8.529h34.116c2.849,0,5.51-1.424,7.096-3.795l14.525-21.792h41.665l31.66,55.405
			c1.518,2.661,4.35,4.299,7.403,4.299h34.116v-17.058H461.267z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M251.622,307.406h-47.643l-23.088-23.088c-1.595-1.604-3.77-2.499-6.03-2.499h-42.645c-4.708,0-8.529,3.813-8.529,8.529
			v59.703c0,4.717,3.821,8.529,8.529,8.529h34.116v85.291c0,3.233,1.825,6.184,4.717,7.633c1.203,0.597,2.516,0.896,3.813,0.896
			c1.808,0,3.608-0.571,5.117-1.706l34.116-25.587c2.149-1.612,3.412-4.145,3.412-6.823v-21.493l39.447-31.549
			c2.021-1.62,3.198-4.068,3.198-6.661v-42.645C260.151,311.219,256.33,307.406,251.622,307.406z M243.093,354.487l-39.447,31.549
			c-2.021,1.621-3.198,4.068-3.198,6.661v21.323l-17.058,12.794v-76.762c0-4.717-3.821-8.529-8.529-8.529h-34.116v-42.645h30.585
			l23.088,23.088c1.595,1.603,3.77,2.499,6.03,2.499h42.645V354.487z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M79.821,183.607l-25.587-42.645c-1.535-2.567-4.316-4.137-7.309-4.137h-8.529v17.058h3.702l19.677,32.794l-20.879,20.879
			c-1.604,1.595-2.499,3.77-2.499,6.03v25.587H12.808v17.058h34.116c4.708,0,8.529-3.821,8.529-8.529v-30.585l23.088-23.088
			C81.305,191.257,81.834,186.967,79.821,183.607z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M268.689,8.889v10.405l-27.66,6.917c-0.947,0.239-1.851,0.64-2.661,1.177L216.9,41.7l-46.748-23.378l-7.633,15.258
			l51.174,25.587c1.203,0.597,2.508,0.896,3.813,0.896c1.655,0,3.301-0.486,4.725-1.433l24.359-16.239l32.692-8.171
			c3.795-0.947,6.465-4.358,6.465-8.273V8.889H268.689z"
          />
        </g>
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  );
};

export default Travel;

import React from 'react';
import ReactDOM from 'react-dom';

import './assets/styles/main.css';

import App from './App';
import reportWebVitals from './utils/reportWebVitals';
import logger from './utils/logger';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(logger.info.bind(logger));

import React from 'react';

const Music: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.002 512.002"
      {...props}
    >
      <g>
        <g>
          <path
            d="M506.048,125.038c-3.748-2.834-8.609-3.749-13.133-2.469l-212,60c-6.457,1.828-10.915,7.722-10.915,14.433v203.072
			c-8.833-5.123-19.075-8.072-30-8.072c-33.084,0-60,26.916-60,60c0,33.084,26.916,60,60,60s60-26.916,60-60
			c0-1.558,0-183.656,0-183.656l182-51.51v123.238c-8.833-5.123-19.075-8.072-30-8.072c-33.084,0-60,26.916-60,60s26.916,60,60,60
			s60-26.916,60-60c0-1.331,0-255,0-255C512.001,132.302,509.797,127.873,506.048,125.038z M240.001,482.002
			c-16.542,0-30-13.458-30-30s13.458-30,30-30s30,13.458,30,30S256.543,482.002,240.001,482.002z M452.001,422.002
			c-16.542,0-30-13.458-30-30s13.458-30,30-30s30,13.458,30,30S468.543,422.002,452.001,422.002z M482.001,185.659l-182,51.509
			v-28.822l182-51.509V185.659z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M209.305,106.481c-33.28-24.396-57.661-48.558-74.535-73.869L117.481,6.681c-3.667-5.5-10.502-7.951-16.827-6.036
			c-6.326,1.916-10.653,7.747-10.653,14.357v235.072c-8.833-5.123-19.075-8.072-30-8.072c-33.084,0-60,26.916-60,60
			c0,33.084,26.916,60,60,60c33.084,0,60-26.916,60-60V63.395c17.927,23.099,41.489,45.234,71.567,67.283
			c11.542,8.46,18.433,22.039,18.433,36.325c0,24.813-20.187,45-45,45c-8.284,0-15,6.716-15,15s6.716,15,15,15
			c41.355-0.001,75-33.646,75-75.001C240.001,143.195,228.525,120.57,209.305,106.481z M60.001,332.002c-16.542,0-30-13.458-30-30
			s13.458-30,30-30c16.542,0,30,13.458,30,30S76.543,332.002,60.001,332.002z"
          />
        </g>
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  );
};

export default Music;
